import React, { useEffect,useCallback } from "react";
import styled from "styled-components";
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from '@web3-react/walletconnect-connector'

import { setupNetwork,switchNetwork } from '../../utils/web3React'
import cogoToast from 'cogo-toast';

//import { useTranslation } from "react-i18next";

import {Button} from "../Button";
import Modal, { ModalProps } from "../Modal";
import ModalActions from "../ModalActions";
import ModalContent from "../ModalContent";
import ModalTitle from "../ModalTitle";
import Spacer from "../Spacer";
import WalletCard from "./components/WalletCard";

import metamaskLogo from "../../assets/svg/metamask-fox.svg";
import walletConnectLogo from "../../assets/svg/wallet-connect.svg";

import { Heading } from '../../components/Heading';
import { FormattedMessage } from 'react-intl';

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`;

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
`;
const POLLING_INTERVAL = 12000
const mainchainId = 1

const walletconnect = new WalletConnectConnector({
  rpc: { [mainchainId]: "https://nodes.pancakeswap.com" },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss,param }) => {
  
  const { account, chainId,activate, deactivate } = useWeb3React()

  var chainIdNow = param?parseInt(param,10):1
  const injected = new InjectedConnector({ supportedChainIds: [chainIdNow] })
  console.log("chainIdNow:",chainIdNow)
  useEffect(() => {
    var cc = Number(chainIdNow)
    if (account&&cc == chainId) {
      onDismiss();
    }
  }, [account, chainId,onDismiss]);

  const login = useCallback(() => {
    const connector = injected
    activate(connector,async (error: Error) => {
      if (error instanceof UnsupportedChainIdError) {
        console.log(error)
        if(chainIdNow==1){
          console.log("switch network")
          const hasSwitch = await switchNetwork()
          if (hasSwitch) {
            activate(connector)
          }
        }else{
          console.log("setup network")
          const hasSetup = await setupNetwork()
          if (hasSetup) {
            activate(connector)
          }
        }
      } else{
        if (error instanceof NoEthereumProviderError ) {
          cogoToast.error('No provider was found')
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector
            walletConnector.walletConnectProvider = null
          }
          cogoToast.error('Authorization Error,Please authorize to access your account')
        } else {
          cogoToast.error(error.name+error.message)
        }
      }
    })
  }, [activate]);

  return (
    <Modal>
      <Heading level={2} color="blueMedium" style={{ margin: '20px auto' }}>
        <FormattedMessage defaultMessage="Connect a wallet" />
      </Heading>
      <ModalContent>
          <StyledWalletsWrapper>
            <WalletCard
              icon={metamaskLogo}
              onConnect={
                () => login()//connect("injected")
              }
              title="Metamask"
            />
          
        </StyledWalletsWrapper>
      </ModalContent>

      <Spacer size="sm" />
      <ModalActions>
        <Button className='mt20' fullWidth={true} label={"Cancel"}  onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default WalletProviderModal;
