import React, { useCallback,useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { CheckBox } from 'grommet';
import Spacer from "../../../components/Spacer";
// eslint-disable-next-line import/no-extraneous-dependencies
import { SendOptions } from 'web3-eth-contract';
import { ByteVectorType, ContainerType, NumberUintType } from '@chainsafe/ssz';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { AbstractConnector } from '@web3-react/abstract-connector';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { Alert as AlertIcon } from 'grommet-icons';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { BeaconChainValidator, TransactionStatus } from '../types';
import { bufferHex } from '../../../utils/SSZ';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { Paper } from '../../../components/Paper';
import { Heading } from '../../../components/Heading';
import { TopupInput } from '../../../components/TopUpInput';
import shortenAddress from '../../../utils/shortenAddress';
import { Alert } from '../../../components/Alert';
import TopUpTransactionModal from './TopUpTransactionModal';
import {
  fortmaticTxRejected,
  isLedgerTimeoutError,
  ledgerTxRejected,
  metamaskTxRejected,
} from '../../../utils/transactionErrorTypes';
import {
  PRICE_PER_VALIDATOR,
  TICKER_NAME,
  CONTRACT_ADDRESS,
  ETHER_TO_GWEI,
} from '../../../utils/envVars';

import { provider } from "web3-core";
import useTokenBalance from "../../../hooks/useTokenBalance";
import useAllowanceEx from "../../../hooks/useAllowanceEx";
import useApproveEx from "../../../hooks/useApproveEx";
import useStakeSnp from "../../../hooks/useStakeSnp";
import { networkID, contractAddresses } from "../../../constants";
import {getBalanceNumber,getFullDisplayBalance} from "../../../utils/formatBalance";
import { getContract,getBridgeContract } from "../../../utils/erc20";

import cogoToast from 'cogo-toast';

const depositDataContainer = new ContainerType({
  fields: {
    pubkey: new ByteVectorType({
      length: 48,
    }),
    withdrawalCredentials: new ByteVectorType({
      length: 32,
    }),
    amount: new NumberUintType({
      byteLength: 8,
    }),
    signature: new ByteVectorType({
      length: 96,
    }),
  },
});

const InputContainer = styled.div`
  margin: auto auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const SubmitButton = styled(Button)`
  height: 50px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin: 10px;
  }
`;

const TopUpDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
  border: 1px solid lightblue;
  background: #eaf6f9;
  border-radius: 5px;
  padding: 10px 20px;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    justify-content: flex-start;
    align-items: left;
  }
`;

const BoldGreen = styled.span`
  color: ${(p: { theme: any; fontSize: number }) => p.theme.green.dark};
  font-size: ${(p: { theme: any; fontSize: number }) => p.fontSize}px;
  font-weight: bold;
`;

const TopupPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { account,chainId, library } = useWeb3React<Web3Provider>()

  const waitTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Waiting For Transaction`,
    });
  }, [formatMessage]);


  const successfulTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Transaction Successful`,
    });
  }, [formatMessage]);

  const failTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Transaction Failed`,
    });
  }, [formatMessage]);
  

  const balance = useTokenBalance(contractAddresses.snp[networkID]);
  const lpContract = useMemo(() => {
    if (library)
      return getContract(library.provider as provider, contractAddresses.snp[networkID]);
    return null
  }, [library]);

  const lpBridgeContract = useMemo(() => {
    if (library)
      return getBridgeContract(library.provider as provider, contractAddresses.seelebridge[networkID]);
    return null
  }, [library]);

  const allowanceEx = useAllowanceEx(lpContract,contractAddresses.seelebridge[networkID]);
  const {onApproveEx}  = useApproveEx(lpContract,contractAddresses.seelebridge[networkID]);

  const [requestedApproval, setRequestedApproval] = useState(false);
  const handleApprove = useCallback(async () => {
    setRequestedApproval(true);
    const { hide } = cogoToast.loading(waitTxText, { hideAfter: 0 });
    try {
      const txHash = await onApproveEx();
      console.log(txHash);
      // user rejected tx or didn't go thru
      if (!txHash) {
        cogoToast.error(failTxText);
        setRequestedApproval(false);
      }else{
        cogoToast.success(successfulTxText);
      }
    } catch (e) {
      console.log(e);
      cogoToast.error(failTxText);
    }
    setRequestedApproval(false);
    hide();
  }, [onApproveEx, setRequestedApproval]);

  const { onStakeSnp } = useStakeSnp(lpBridgeContract);
  const [pendingDepositTx, setPendingDepositTx] = useState(false);
  
  const maxTopupValue = useMemo(
    () => getBalanceNumber(balance),
    [balance]
  );


  const [value, setValue] = React.useState("0");

  const [showTxModal, setShowTxModal] = React.useState(false);

  const [txHash, setTxHash] = React.useState('');

  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(
    'not_started'
  );


  const [termA, setTermA] = useState(false);


  const submitBtnTooltipText = React.useMemo(() => {
    var valueBig = new BigNumber(value)
    if (valueBig.isLessThanOrEqualTo(0) || valueBig.isGreaterThan(balance.dividedBy(new BigNumber(10).pow(18))))
    {
        return formatMessage({
          defaultMessage: 'Please enter a valid value.',
        });
    }
    /*
    if (value <= 0 || value > maxTopupValue)
      return formatMessage({
        defaultMessage: 'Please enter a valid value.',
      });
    */
    if (!termA)
      return formatMessage({
        defaultMessage: 'Please accept the conditions above.',
      });
    return '';
  }, [value, termA, maxTopupValue, formatMessage]);

  return (
    <div>
      <ReactTooltip />
      {showTxModal && (
        <TopUpTransactionModal
          txHash={txHash}
          transactionStatus={transactionStatus}
          onClose={() => setShowTxModal(false)}
        />
      )}

      <Paper className="mt30">
        <TopUpDetailsContainer className="my30">
          <div className="details-item">
            <Text weight={600} color="blueDark">
              <FormattedMessage defaultMessage="Public key" />
            </Text>
            <BoldGreen className="mr10" fontSize={20}>
              {shortenAddress(account, 6)}
            </BoldGreen>
          </div>
          <div className="details-item">
            <Text weight={600} color="blueDark">
              <FormattedMessage defaultMessage="Current balance" />
            </Text>
            <BoldGreen className="mr10" fontSize={20}>
              {`${getBalanceNumber(balance).toFixed(4)} ${TICKER_NAME}`}
            </BoldGreen>
          </div>
        </TopUpDetailsContainer>
        <Heading level={3} color="blueDark">
          <FormattedMessage defaultMessage="Risks and acknowledgements:" />
        </Heading>
        <div className="mt20">
          <CheckBox
            checked={termA}
            onChange={() => setTermA(!termA)}
            label={
              <Text className="checkbox-label ml10">
                <FormattedMessage defaultMessage="I am certain that I am cross-chain deposit SNP funds." />
              </Text>
            }
          />
        </div>
        
        <InputContainer className="mt30">
          <TopupInput
            value={value}
            setValue={setValue}
            maxValue={getFullDisplayBalance(balance)}
          />
          <Spacer size='sm'/>
          <span data-tip={submitBtnTooltipText}>
          {!allowanceEx.toNumber() ?(
              <SubmitButton
              className="ml10"
              active={!requestedApproval}
              label={formatMessage({ defaultMessage: 'Wallet Approve' })}
              rainbow
              disabled = {requestedApproval}
              onClick={handleApprove}
            />
          ):(
            <SubmitButton
              className="ml10"
              label={formatMessage({ defaultMessage: 'Deposite SNP' })}
              active={!pendingDepositTx}
              rainbow
              disabled={
                new BigNumber(value).isLessThanOrEqualTo(0) ||
                new BigNumber(value).isGreaterThan(balance.dividedBy(new BigNumber(10).pow(18))) ||
                !termA||
                pendingDepositTx
              }
              onClick={async () => {
                setPendingDepositTx(true);
                const { hide } = cogoToast.loading(waitTxText, { hideAfter: 0 });
                try {

                  const txHash = await onStakeSnp(contractAddresses.snp[networkID],account,value.toString());
                  console.log(txHash);
                  //cogoToast.success(successfulTxText);
                  // user rejected tx or didn't go thru
                  if (!txHash) {
                    cogoToast.error(failTxText);
                    setPendingDepositTx(false);
                  }else{
                    cogoToast.success(successfulTxText);
                  }
                } catch (e) {
                  console.log(e);
                  cogoToast.error(failTxText);
                }
                setPendingDepositTx(false);
                hide();
              }
              }
            />
          )}
          </span>
        </InputContainer>
      </Paper>
    </div>
  );
};

export default TopupPage;
