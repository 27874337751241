import React,{useState} from 'react'
import BigNumber from "bignumber.js";
import styled from 'styled-components';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
  } from 'grommet';
  import { FormattedMessage, useIntl } from 'react-intl';
  import { Text } from '../Text';
  import { Button } from '../Button';
  import { Link } from '../Link';
  import useMobileCheck from '../../hooks/useMobileCheck';
  import { routesEnum } from '../../pages/Routes';
  import {getBalanceNumber} from "../../utils/formatBalance";
  import shortenAddress from '../../utils/shortenAddress';

interface  DelegateTableRowProps{
    data:any;
    index?: any;
    pendingtx?: boolean;
    onUnDelegate: (address: string,amount: string) => void;
 }

 const TableCellStyled = styled(TableCell)`
  border-bottom: 1px solid lightblue;
  padding-left: 3px;
  padding-right: 3px;
`;

const DelegateTableRow: React.FC<DelegateTableRowProps> = ({index,data,onUnDelegate,pendingtx}) => {
    const { formatMessage } = useIntl();
    const mobile = useMobileCheck('724px');
    return (
        <React.Fragment>
            <TableRow>
                
                <TableCellStyled scope="col" border="bottom">
                    <Text>{shortenAddress(data.delegation.validator_address, 4)}</Text>
                </TableCellStyled>
                
                
                <TableCellStyled scope="col" border="bottom">
                    <Text>{getBalanceNumber(new BigNumber(data.balance.amount)).toFixed(3)}</Text>
                </TableCellStyled>
                
                <TableCellStyled scope="col" border="bottom">
                    <Button label={formatMessage({ defaultMessage: 'UnDelegate' })} 
                    onClick={() => onUnDelegate(data.delegation.validator_address,data.balance.amount)} 
                    disabled={pendingtx}
                    />
                </TableCellStyled>
            </TableRow>
        </React.Fragment>
    )
}

export default DelegateTableRow