import { ethers } from 'ethers'
import { networkID,seeleNetworkID } from "../constants";

const POLLING_INTERVAL = 12000

export const getLibrary = (provider): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL
    return library
  }

export const switchNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    const chainId = networkID
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
          }
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to switch the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't switch the network on metamask because window.ethereum is undefined")
    return false
  }
}

export const setupNetwork = async () => {
    const provider = window.ethereum
    if (provider) {
      const chainId = seeleNetworkID
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: 'Seele Chain Mainnet',
              nativeCurrency: {
                name: 'SEELE',
                symbol: 'seele',
                decimals: 18,
              },
              rpcUrls: [`https://rpc.seelen.pro`,`https://rpc1.seelen.pro`],
              blockExplorerUrls: [`https://seeleview.net/`],
            },
          ],
        })
        return true
      } catch (error) {
        console.error('Failed to setup the network in Metamask:', error)
        return false
      }
    } else {
      console.error("Can't setup the Seele network on metamask because window.ethereum is undefined")
      return false
    }
  }

  /**
 * Seele Wallet requires a different sign method
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
    if (window.SeeleChain) {
      const { signature } = await window.SeeleChain.seeleSign(account, message)
      return signature
    }
  
    /**
     * Wallet Connect does not sign the message correctly unless you use their method
     * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
     */
    if (provider.provider?.wc) {
      const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
      const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
      return signature
    }
  
    return provider.getSigner(account).signMessage(message)
  }

  /**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `https://bscscan.com//images/tokens/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}