import { useCallback } from "react";

import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { Contract } from "web3-eth-contract";
import { useWeb3React } from "@web3-react/core";
import { approve } from "../snp/utils";

const useApproveEx = (lpContract: Contract, approveAddress: string) => {
  //const { account }: { account: string; ethereum: provider } = useWallet();
  const {  account } = useWeb3React();
  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, approveAddress, account);
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, lpContract, approveAddress]);

  return { onApproveEx: handleApprove };
};

export default useApproveEx;
