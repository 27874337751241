import React from "react";
import styled from 'styled-components';
import { Paper } from '../../Paper';

interface WalletCardProps {
  icon: string;
  onConnect: () => void;
  title: string;
}

export const Logo = styled.img`
  height: 50px;
  width: 50px;
  display: block;
  margin: 24px;
`;

const StyledPaper = styled(Paper)`
  box-shadow: ${(p: { isActive: boolean }) =>
    p.isActive && `0 0 10px rgba(0, 0, 0, 0.5)`};
  width: 350px;
  margin: 5px;
  align-items: center;
  cursor: ${p => (p.error ? 'not-allowed' : 'pointer')};
  &:hover {
    box-shadow: ${p => (p.error ? 'none' : '0px 8px 17px rgba(0, 0, 0, 0.15)')};
    transition: ${p => (p.error ? 'none' : 'transform 0.1s;')};
    transform: ${p => (p.error ? 'none' : 'scale(1.02)')};
  }
`;

const WalletText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WalletCard: React.FC<WalletCardProps> = ({ icon, onConnect, title }) => {
  //const { t } = useTranslation();
  return (
    <StyledPaper
      pad="xsmall"
      className="wallet-button flex flex-row relative"
      onClick={onConnect}
      isActive= {true}
    >
    <Logo src={icon} />
    <WalletText>{title}</WalletText>
    </StyledPaper>
    
  );
};

export default WalletCard;
