import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect'
import {
  Props,
} from './types';
import { Text } from '../../components/Text';
//import WalletConnectModal from './components/WalletConnectModal';

import TopupPage from './components/TopupPage';
import Spinner from '../../components/Spinner';
import { PageTemplate } from '../../components/PageTemplate';

import { Alert } from '../../components/Alert';
import { Link } from '../../components/Link';

import { Paper } from '../../components/Paper';
import { ConnectWalletButton } from '../../components/ConnectWalletButton';


import { useWallet } from "use-wallet";


const SubTextContainer = styled.div``;


const DelegatorDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid lightblue;
  background: #eaf6f9;
  border-radius: 5px;
  @media screen and (max-width: 724px) {
    flex-direction: column;
    .details-item {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const _TopUpPage: React.FC<Props> = () => {
  
  const [loading, setLoading] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();
 
  const { account,chainId } = useWeb3React()


  return (
    <>
      <PageTemplate
        title={formatMessage({ defaultMessage: 'Become a delegator' })}
      >
        <SubTextContainer className="mt20">
          <Text className="py10">
            <FormattedMessage
              defaultMessage="Deposit SNP funds to Seele cross-chain bridge.Wait the mainnet to active your assets."
            />
          </Text>
        </SubTextContainer>

        <DelegatorDetailsContainer className="my10">
          {!!account&&chainId==1 ? (
            <TopupPage/>
          ):(
            <Paper className="mt30">
              <ConnectWalletButton chainId="1"/>
            </Paper>
          )}
        </DelegatorDetailsContainer>
      </PageTemplate>
    </>
  );
};

export const TopUpPage = _TopUpPage;

export default TopUpPage;
