import React, { useEffect,useCallback,useMemo, useState } from 'react';
import styled from 'styled-components';
import _shuffle from 'lodash/shuffle';
import ReactTooltip from 'react-tooltip';
import {
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import { useWeb3React } from '@web3-react/core';

import { provider } from "web3-core";
import cogoToast from 'cogo-toast';
import { networkID,seeleNetworkID, contractAddresses } from "../../constants";

import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FormNext } from 'grommet-icons';
import { Link } from '../../components/Link';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Paper } from '../../components/Paper';
import { ConnectWalletButton } from '../../components/ConnectWalletButton';
import { Alert } from '../../components/Alert';
import Spacer from "../../components/Spacer";
import Spinner from '../../components/Spinner';
import { routesEnum } from '../Routes';
import useMobileCheck from '../../hooks/useMobileCheck';

import { getDelegateContract } from "../../utils/erc20";
import { querySeelechainDelegates,querySeelechainRewards } from '../../utils/queryBeaconchain';

import RewardTableRow  from '../../components/RewardTableRow';
import DelegateTableRow  from '../../components/DelegateTableRow';

import useSnpReward from "../../hooks/useSnpReward";
import useSnpUnDelegate from "../../hooks/useSnpUnDelegate";

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 32px;
`;

const ValidatorsDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid lightblue;
  background: #eaf6f9;
  border-radius: 5px;
  flex-direction: column;
  @media screen and (max-width: 724px) {
    flex-direction: column;
    .details-item {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const TableCellStyled = styled(TableCell)`
  border-bottom: 1px solid lightblue;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #eaf6f9;
  @media screen and (max-width: 724px) {
    flex-direction: column;
    .details-item {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const LoadingCircleDiv = styled.div`
  position: relative;
  .step {
    position: absolute;
    top: 12px;
    left: 18px;
  }
`;

const StakePage:React.FC = () => {
  const { formatMessage } = useIntl();
  const mobile = useMobileCheck('724px');
  const { account,chainId, library  } = useWeb3React()

  const [txtick, setTxtick] = useState(0);
  

  const waitTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Waiting For Transaction`,
    });
  }, [formatMessage]);


  const successfulTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Transaction Successful`,
    });
  }, [formatMessage]);

  const failTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Transaction Failed`,
    });
  }, [formatMessage]);

  const lpDelegateContract = useMemo(() => {
    if (library)
        return getDelegateContract(library.provider as provider, contractAddresses.snpstake[seeleNetworkID]);
    return null
  }, [library]);

  const { onSnpReward } = useSnpReward(lpDelegateContract);
  const { onSnpUnDelegate } = useSnpUnDelegate(lpDelegateContract);

  const [delsstate, setDelsState] = useState({
    totalDelegates: [],
    status: 0,
  });

  useEffect(() => {
    (async () => {
      if (account){
        const response = await querySeelechainDelegates(account);
        setDelsState({
          totalDelegates: response.body.delegates,
          status: response.statusCode,
        });
      }
    })();
  }, [account,txtick]);

  const [rewardstate, setRewardState] = useState({
    totalRewards: [],
    status: 0,
  });

  useEffect(() => {
    (async () => {
      if (account){
        const response = await querySeelechainRewards(account);
        setRewardState({
          totalRewards: response.body.rewards,
          status: response.statusCode,
        });
      }
      
    })();
  }, [account,txtick]);


  const [pendingUnDelegateTx, setPendingUnDelegateTx] = useState(false);

  const syncUnDelegate = async (address: string,amount:string) => {
      setPendingUnDelegateTx(true);
      const { hide } = cogoToast.loading(waitTxText, { hideAfter: 0 });
      try {

      const txHash = await onSnpUnDelegate(address,amount);
      console.log(txHash);
      //cogoToast.success(successfulTxText);
      // user rejected tx or didn't go thru
      if (!txHash) {
          cogoToast.error(failTxText);
          setPendingUnDelegateTx(false);
      }else{
          cogoToast.success(successfulTxText);
          setTxtick(txtick+1);
      }
      } catch (e) {
      console.log(e);
      cogoToast.error(failTxText);
      }
      setPendingUnDelegateTx(false);
      hide();
  }

  const onUnDelegate = (address: string,amount :string) => {
    syncUnDelegate(address,amount)
  };

  const delegatesTableBodyRender = React.useMemo(
    () => {
      return delsstate.totalDelegates?delsstate.totalDelegates.map(delegate => {
        return (
          <DelegateTableRow  data={delegate} onUnDelegate= {onUnDelegate} pendingtx={pendingUnDelegateTx}/>
        );
      }):(<div/>);
    },
		
		[delsstate,pendingUnDelegateTx]
	);

  const [pendingRewardTx, setPendingRewardTx] = useState(false);

  const syncReward = async (address: string) => {
      setPendingRewardTx(true);
      const { hide } = cogoToast.loading(waitTxText, { hideAfter: 0 });
      try {

      const txHash = await onSnpReward(address);
      console.log(txHash);
      //cogoToast.success(successfulTxText);
      // user rejected tx or didn't go thru
      if (!txHash) {
          cogoToast.error(failTxText);
          setPendingRewardTx(false);
      }else{
          cogoToast.success(successfulTxText);
          setTxtick(txtick+1);
      }
      } catch (e) {
      console.log(e);
      cogoToast.error(failTxText);
      }
      setPendingRewardTx(false);
      hide();
  }

  const onReward = (address: string) => {
      syncReward(address)
  };

  const rewardsTableBodyRender = React.useMemo(
    () => {
      return rewardstate.totalRewards?rewardstate.totalRewards.map(reward => {
        return (
          <RewardTableRow  data={reward} onReward={onReward} pendingtx={pendingRewardTx}/>
        );
      }):(
        <div/>
      );
    },
		
		[rewardstate,pendingRewardTx]
	);

  return (
    <PageTemplate
      title={formatMessage({ defaultMessage: 'Delegator Stake' })}
    >
      <div id="top" />
      <Subtitle>
        <FormattedMessage defaultMessage="Withdraw rewards or your delegate funds" />
      </Subtitle>
      
      <ValidatorsDetailsContainer className="my10">
        {!!account&&chainId==seeleNetworkID ? (
            <>
                <Spacer/>
                <Tabs>
                    <Tab title={formatMessage({ defaultMessage: 'Rewards' })}>
                        <Paper style={{ marginTop: '1rem' }}>
                        <Table>
                        <TableHeader>
                            <TableRow>
                            <TableCellStyled scope="col" border="bottom">
                                <Text weight={600}>
                                <FormattedMessage defaultMessage="Validator" />
                                </Text>
                            </TableCellStyled>
                            
                            <TableCellStyled scope="col" border="bottom">
                                <Text weight={600}>
                                <FormattedMessage defaultMessage="Seele" />
                                </Text>
                            </TableCellStyled>
                            
                            <TableCellStyled scope="col" border="bottom">
                                <Text weight={600}>
                                <FormattedMessage defaultMessage="Action" />
                                </Text>
                            </TableCellStyled>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {rewardsTableBodyRender}
                        </TableBody>
                        </Table>
                        </Paper>
                    </Tab>
                    <Tab title={formatMessage({ defaultMessage: 'Delegated Funds' })} >
                        <Paper style={{ marginTop: '1rem' }}>
                        <Table>
                        <TableHeader>
                            <TableRow>
                            <TableCellStyled scope="col" border="bottom">
                                <Text weight={600}>
                                <FormattedMessage defaultMessage="Validator" />
                                </Text>
                            </TableCellStyled>
                            
                            <TableCellStyled scope="col" border="bottom">
                                <Text weight={600}>
                                <FormattedMessage defaultMessage="Snp" />
                                </Text>
                            </TableCellStyled>
                            
                            <TableCellStyled scope="col" border="bottom">
                                <Text weight={600}>
                                <FormattedMessage defaultMessage="Action" />
                                </Text>
                            </TableCellStyled>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {delegatesTableBodyRender}
                        </TableBody>
                        </Table>
                        </Paper>
                    </Tab>
                </Tabs>
            </>
          ):(
            <ButtonContainer>
            <Paper className="mt30">
              <ConnectWalletButton chainId={seeleNetworkID.toString()}/>
            </Paper>
            </ButtonContainer>
        )}  
        
      </ValidatorsDetailsContainer>
    </PageTemplate>
  );
};

export default StakePage;
