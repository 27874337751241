import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { HashLink as Anchor } from 'react-router-hash-link';
import styled from 'styled-components';
import { Link } from '../../components/Link';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { PRICE_PER_VALIDATOR } from '../../utils/envVars';

const FAQStyles = styled.div`
  section {
    margin-top: 30px;
  }
  a {
    text-decoration: none;
  }
`;

const BlockQuote = styled.div`
  margin-top: 10px;
  padding-left: 20px;
  border-left: 5px solid lightgray;
`;

const SectionTitle = styled(Heading)`
  margin-top: 30px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`;

export const FAQ = () => {
  const { formatMessage } = useIntl();
  return (
    <PageTemplate
      title={formatMessage({ defaultMessage: 'Launchpad FAQs' })}
      description={formatMessage({
        defaultMessage: 'Answers to common questions on becoming a delegator.',
      })}
    >
      <FAQStyles>
        <section>
          <Anchor to="#introduction" id="introduction">
            <SectionTitle level={3}>
              <FormattedMessage defaultMessage="Introduction" />
            </SectionTitle>
          </Anchor>

          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="What exactly is a delegator?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="A delegator is an entity that participates in the consensus of the
                  Seele network."
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="Or in other words, the main task for delegators is to distribute voting power among validator nodes. 
                This gives decentralization to Seele network."
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="There are validator nodes doing their thing, all other holders of SNPs can participate in the consensus as well, but not by running servers, but by controlling validators. "
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage defaultMessage="Importantly, a validator’s vote is weighted by the amount it has at stake by delegators." />
            </Text>
          </section>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="What is the deposit contract?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="You can think of the deposit contract as a transfer of funds between Ethereum accounts and
                  Seele accounts."
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="It specifies who is staking, who is delegating, how much is being
                  staked, and who can withdraw the funds."
              />
            </Text>
          </section>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="Why do I need to have funds at stake?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="As a delegator, you'll need to have funds at stake so you can be penalized
                  for behaving dishonestly."
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="In other words, to keep you honest, your actions need to have
                  financial consequences."
              />
            </Text>
          </section>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="How much SNP do I need to stake to become a delegator?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="Before you can become a delegator and start to secure the network, you
                  need to stake {pricePerValidator} SNP."
                values={{
                  pricePerValidator: <strong>{PRICE_PER_VALIDATOR}</strong>,
                }}
                description="{pricePerValidator} represents deposit amount styled in bold"
              />
            </Text>
          </section>
          
          <section>
            <Heading level={4}>
              <FormattedMessage
                defaultMessage="When can I withdraw my funds?"
              />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="You can withdraw your funds when the Seele network open Two-way assets cross-chain,possibly in November."
              />
            </Text>
          </section>
        </section>
        <section>
          <Anchor to="#seelelaunch" id="seelelaunch">
            <SectionTitle level={3}>
              <FormattedMessage defaultMessage="Seele network launch" />
            </SectionTitle>
          </Anchor>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="How dose the Seele network launch?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="In order to ensure the safety of assets, Seele network will be launched in phases."
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage defaultMessage="The key steps are the following:" />
            </Text>
            <ul>
              <li>
                <FormattedMessage defaultMessage="Launch bridge contract,the delegators deposit funds to cross to Seele network." />
              </li>
              <li>
                <FormattedMessage defaultMessage="Launch the Seele network,cross the deposited funds to the delegators." />
              </li>
              <li>
                <FormattedMessage defaultMessage="The delegators chosse the vaildator to stake funds to secure the seele network." />
              </li>
              <li>
                <FormattedMessage defaultMessage="Open Two-way assets cross-chain,possibly in November." />
              </li>
            </ul>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="Now, we are in the first step.We need enough SNP funds to safely start Seele network."
              />
            </Text>
          </section>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="When to start the second step(launch the Seele network)?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="Enough cross-chain SNP funds to safely start Seele network or end of October at the latest."
              />
            </Text>
          </section>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="What are the future plans for the seele network?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="The mainnet already supports EVM, which means that all Ethereum applications can be seamlessly migrated to the mainnet.
                 We welcome developers to build the ecosystem of Seele. For example, Swap market, NFT market, etc."
              />
            </Text>
          </section>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="How to use the mainnet wallet?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="We recommend metamask, metamask is the main wallet of Ethereum with millions of users."
              />
            </Text>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="The Seele exclusive wallet may be developed in the future,
                 and ecological developers are also welcome to participate in research and development."
              />
            </Text>
          </section>
        </section>
        
        <section>
          <Anchor to="#support" id="support">
            <SectionTitle level={3}>
              <FormattedMessage defaultMessage="Support" />
            </SectionTitle>
          </Anchor>
          <section>
            <Heading level={4}>
              <FormattedMessage defaultMessage="Where can I find troubleshooting support?" />
            </Heading>
            <Text className="mt10">
              <FormattedMessage
                defaultMessage="If you have questions, Seele community is a good place to get
                  help! You can find support on {telegram}."
                values={{
                  telegram: (
                    <Link inline to="https://t.me/seelenseelen2020">
                      Telegram
                    </Link>
                  ),
                }}
                description="{discord} and {reddit} are links to EthStaker forums on Discord and Reddit respectively"
              />
            </Text>
          </section>
        </section>
      </FAQStyles>
    </PageTemplate>
  );
};

export default FAQ;