import React from 'react';
import styled from 'styled-components';
import _shuffle from 'lodash/shuffle';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FormNext } from 'grommet-icons';
import { Link } from '../../components/Link';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import {
  BEACONCHAIN_URL,
} from '../../utils/envVars';
import { ClientCard } from '../Congratulations/ClientCard';
import Step1 from '../../static/step1.png';
import Step2 from '../../static/step2.png';
import Step3 from '../../static/step3.png';
import { Alert } from '../../components/Alert';

const ChecklistPageStyles = styled.div`
  section {
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    > h3 {
      border-bottom: 1px solid lightgray;
      margin-bottom: 5px;
    }
  }
  label {
    padding: 1rem;
  }
  .sub-checklist-item {
    margin-top: -0.5rem;
    margin-left: 1.5rem;
  }
  .checkbox-label {
    margin-left: 0.5rem;
  }
  ul {
    padding-left: 0px;
    padding-top: 16px;
  }
  @media screen and (max-width: 1080px) {
    section {
      background-color: white;
      margin: 0px;
      padding: 16px;
      flex-wrap: wrap;
    }
  }
`;

const CodeSnippet = styled.div`
  padding: 10px;
  font-family: Courier, sans-serif;
  font-size: 1em;
  line-height: 1.3;
  color: #fff;
  background-color: #597ea3;
  border-radius: 6px;
  margin: 10px 0;

  code {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ClientContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 200px), 1fr));
  gap: 1rem;
  margin: 1.5rem 0 2.5rem;
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 32px;
`;

const PortTable = styled.table`
  margin: 1rem auto;
  color: #212529;

  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  thead th {
    vertical-align: bottom;
  }

  tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
`;

const SectionHeader = styled.div`
  margin: 3rem 0 1rem;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  &:before {
    content: '';
    display: block;
    height: 3rem;
    margin-top: -3rem;
    visibility: hidden;
  }
`;

const RainbowHeader = styled(SectionHeader as any)`
  margin: 3rem 1rem 1rem;
  background-image: ${p =>
    `linear-gradient(to right, ${p.theme.rainbowLight})`};
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
  gap: 1rem;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  padding: 24px;
  border: 1px solid ${p => p.theme.gray.dark};
  border-radius: 4px;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    margin: 0px;
    margin-top: 16px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.15);
    transition: transform 0.1s;
    transform: scale(1.02);
  }
`;

const BoldGreen = styled.span`
  color: ${(p: { theme: any; fontSize: number }) => p.theme.green.dark};
  font-size: ${(p: { theme: any; fontSize: number }) => p.fontSize}px;
  font-weight: bold;
`;

const StyledLink = styled(Link as any)`
  width: 100%;
`;

interface Client {
  header: string;
  imgUrl: any;
  linkText: string;
}

export const Checklist = () => {
  const { formatMessage } = useIntl();
  const clientInfo: Client[] = [
    {
      header: formatMessage({
        defaultMessage: 'Step 1',
      }),   
      imgUrl: Step1,
      linkText: formatMessage({
        defaultMessage: 'Connect your wallet',
      }),
    },
    {
      header: formatMessage({
        defaultMessage: 'Step 2',
      }),
      imgUrl: Step2,
      linkText: formatMessage({
        defaultMessage: 'Approve wallet',
      }),
    },
    {
      header: formatMessage({
        defaultMessage: 'Step 3',
      }),
      imgUrl: Step3,
      linkText: formatMessage({
        defaultMessage: 'Deposit SNP',
      }),
    },
  ];

  return (
    <PageTemplate
      title={formatMessage({ defaultMessage: 'Seele Launchpad Checklist' })}
    >
      <div id="top" />
      <Subtitle>
        <FormattedMessage defaultMessage="This checklist will help you understand the role of a delegator and prepare you for the role." />
      </Subtitle>
      <CardContainer>
        <StyledLink to="#section-one" inline isTextLink={false}>
          <Card>
            <div>
              <Heading level={4} className="mb10">
                <FormattedMessage defaultMessage="Section 1" />
              </Heading>
              <BoldGreen className="mr10" fontSize={24}>
                <FormattedMessage defaultMessage="Before you start" />
              </BoldGreen>
            </div>
            <FormNext size="large" />
          </Card>
        </StyledLink>
        <StyledLink to="#section-two" inline isTextLink={false}>
          <Card>
            <div>
              <Heading level={4} className="mb10">
                <FormattedMessage defaultMessage="Section 2" />
              </Heading>
              <BoldGreen className="mr10" fontSize={24}>
                <FormattedMessage defaultMessage="Deposit SNP" />
              </BoldGreen>
            </div>
            <FormNext size="large" />
          </Card>
        </StyledLink>
        <StyledLink to="#section-three" inline isTextLink={false}>
          <Card>
            <div>
              <Heading level={4} className="mb10">
                <FormattedMessage defaultMessage="Section 3" />
              </Heading>
              <BoldGreen className="mr10" fontSize={24}>
                <FormattedMessage defaultMessage="After depositing" />
              </BoldGreen>
            </div>
            <FormNext size="large" />
          </Card>
        </StyledLink>
      </CardContainer>
      <ChecklistPageStyles>
        <SectionHeader id="section-one">
          <Heading level={3}>
            <FormattedMessage defaultMessage="Section 1 - Before you start" />
          </Heading>
          <Text className="mt10">
            <FormattedMessage defaultMessage="Review this section before deciding to proceed with delegator setup." />
          </Text>
        </SectionHeader>
        <Alert variant="warning" className="my40 mx15">
          <Heading level={4}>
            <FormattedMessage defaultMessage="Recommendation disclaimer" />
          </Heading>
          <Text className="mt20">
            <FormattedMessage
              defaultMessage="During the startup phase of the main network, it may become unstable due to failures and attacks.
               Enough asset staking is required to protect the operation of the network. Do your own research before depositing funds."
            />
          </Text>
        </Alert>
        <section>
          <Heading level={3}>
            <FormattedMessage defaultMessage="DPOS" />
          </Heading>
          <Text className="mt20">
            <FormattedMessage
              defaultMessage="Delegated Proof of Stake (DPoS) is a blockchain consensus mechanism in which users who hold that blockchain’s coin are able to vote for “delegates.”
               Then, these elected delegates make important decisions for the entire network, like deciding which transactions are valid and setting protocol rules."
            />
          </Text>
          <Text className="mt20">
            <FormattedMessage
              defaultMessage="SNP serves as the network’s staking coin, and stakers bond their SNP coins as collateral.
               On Seele, delegates are known as Validators.The network also consists of non-validators called Delegators.
              Delegators can stake their SNP coins in support of other users who they want to select as validators."
            />
          </Text>
        </section>
        <SectionHeader id="section-two">
          <Heading level={3}>
            <FormattedMessage defaultMessage="Section 2 - Deposit SNP" />
            <Text className="mt10">
              <FormattedMessage defaultMessage="Use this as a reference during you deposit snp to seele cross-chain bridge important steps." />
            </Text>
          </Heading>
        </SectionHeader>
        
        <section>
          <Heading level={3}>
            <FormattedMessage defaultMessage="Steps" />
          </Heading>
          <ClientContainer>
            {clientInfo.map(client => (
              <ClientCard
                className="mt10"
                header={client.header}
                imgUrl={client.imgUrl}
                key={client.header}
                url=""
                linkText={client.linkText}
              />
            ))}
          </ClientContainer>
        </section>
        
        <SectionHeader id="section-three">
          <Heading level={3}>
            <FormattedMessage defaultMessage="Section 3 - After depositing" />
            <Text className="mt10">
              <FormattedMessage defaultMessage="Waiting for us to unify the transfer of assets to the main network." />
            </Text>
          </Heading>
          <Alert variant="info" className="mt20">
            <FormattedMessage defaultMessage="These steps are very important in order to protect your assets." />
          </Alert>
        </SectionHeader>
        
        <section>
          <Heading level={3} className="mt10">
            <FormattedMessage defaultMessage="In Mainnet" />
          </Heading>{' '}
          <Text className="mt10">
            <FormattedMessage
              defaultMessage="You can use your wallet such as Metamask to control your assets.Staking SNP to secure Seele network and earn rewards."
              values={{
                beaconchain: (
                  <Link primary inline to={BEACONCHAIN_URL}>
                    Beaconcha.in
                  </Link>
                ),
                beaconscan: (
                  <Link primary inline to="https://beaconscan.com/">
                    BeaconScan
                  </Link>
                ),
              }}
              description="{variables} are Beacon Chain block explorers, with links to each (do not translate names)"
            />
          </Text>
        </section>
        <RainbowHeader>
          <FormattedMessage
            defaultMessage="If you have questions, Seele community is a good place to get help!
                You can find support on {telegram}."
            values={{
              telegram: (
                <Link primary inline to="https://t.me/seelenseelen2020">
                  Telegram
                </Link>
              ),
            }}
            description="{variables} social media platform links to Discord and Reddit (do not translate names)"
          />
        </RainbowHeader>
      </ChecklistPageStyles>
    </PageTemplate>
  );
};

export default Checklist;
