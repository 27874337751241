import React from 'react'
import styled from 'styled-components';
import {Button} from "./Button";
import WalletProviderModal from './WalletProviderModal';
import { FormattedMessage, useIntl } from 'react-intl';
import useModal from "../hooks/useModal";
//import useAuth from '../hooks/useAuth'
import { isMobile } from 'react-device-detect'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'

const WalletButton = styled(Button)`
  height: 50px;
`;

interface ConnectWalletButtonProps {
    chainId: string;
  }

export const ConnectWalletButton = (props: ConnectWalletButtonProps) => {
  const { activate } = useWeb3React()
  const { formatMessage } = useIntl();
  const { chainId } = props;
  var chainIdNow = chainId?parseInt(chainId,10):1
  const injected = new InjectedConnector({ supportedChainIds: [chainIdNow] })
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal param={chainId}/>);
  return (
    <WalletButton
        className="py0"
        label={formatMessage({ defaultMessage: 'Connect Wallet' })}
        rainbow
        onClick={() => {
            isMobile ? activate(injected) : onPresentWalletProviderModal()
        }}
    />
  )
}
