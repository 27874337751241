import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { supportedLanguages } from '../intl';

import FAQ from "./FAQ";
import Languages from "./Languages";
import LandingPage from "./Landing";
import Checklist from "./Checklist";
import NotFoundPage from "./NotFound";
import TopUpPage from "./TopUp";
import ValidatorsPage from "./Validators";
import ValidatorDetailPage from "./ValidatorDetail";
import StakePage from "./Stake";

import ScrollToTop from '../utils/ScrollToTop';

type RouteType = {
  path: string;
  component: FunctionComponent;
  exact?: boolean;
};

export enum routesEnum {
  FaqPage = '/faq',
  checklistPage = '/checklist',
  topUpPage = '/topup',
  ValidatorsPage = '/validators',
  ValidatorDetailPage = '/validators/:address',
  StakePage = '/stake',
  landingPage = '/',
  notFoundPage = '/*',
  languagesPage = '/languages',
}
const routes: RouteType[] = [
  {
    path: routesEnum.FaqPage,
    exact: true,
    component: FAQ,
  },
  {
    path: routesEnum.checklistPage,
    exact: true,
    component: Checklist,
  },
  {
    path: routesEnum.topUpPage,
    exact: true,
    component: TopUpPage,
  },

  {
    path: routesEnum.ValidatorDetailPage,
    exact: true,
    component: ValidatorDetailPage,
  },
  
  {
    
    path: routesEnum.ValidatorsPage,
    exact: true,
    component: ValidatorsPage,
  },

  {
    
    path: routesEnum.StakePage,
    exact: true,
    component: StakePage,
  },
  
  {
    path: routesEnum.languagesPage,
    exact: true,
    component: Languages,
  },

  { path: routesEnum.landingPage, exact: true, component: LandingPage },
  // NOTE: this wildcard route must be the last index of the routes array
  { path: routesEnum.notFoundPage, component: NotFoundPage },
];

const localizeRoutes = (locale: String, routes: RouteType[]) => {
  return routes.map(route => {
    const languagePath = route.path.split('/')[1];
    const routeHasLangPath = supportedLanguages.includes(languagePath);
    if (routeHasLangPath || route.path === '/*') {
      return route;
    }
    const localizedRoute: RouteType = {
      path: `/${locale}${route.path}`,
      exact: route.exact,
      component: route.component,
    };
    return localizedRoute;
  });
};

const _Routes = () => {
  const { locale, formatMessage } = useIntl();
  const localizedRoutes = localizeRoutes(locale, routes);

  const title = formatMessage({ defaultMessage: 'Seele Launchpad' });
  const description = formatMessage({
    defaultMessage:
      'Become a delegate and help secure the future of Seele.',
  });
  return (
    <>
      <ScrollToTop>
        <Helmet>
          <html lang={locale} />
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="twitter:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <Switch>
          {localizedRoutes.map((route: RouteType) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              {...route}
              key={route.path}
            />
          ))}
        </Switch>
      </ScrollToTop>
    </>
  );
};

export const Routes = withRouter(_Routes);
