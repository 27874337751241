import React,{useState} from 'react'
import BigNumber from "bignumber.js";
import styled from 'styled-components';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
  } from 'grommet';
  import { FormattedMessage, useIntl } from 'react-intl';
  import { Text } from '../Text';
  import { Button } from '../Button';
  import { Link } from '../Link';
  import useMobileCheck from '../../hooks/useMobileCheck';
  import { routesEnum } from '../../pages/Routes';
  import {getBalanceNumber} from "../../utils/formatBalance";
  import shortenAddress from '../../utils/shortenAddress';

interface  ValidatorTableRowProps{
    data:any;
    index?: any;
 }

 const TableCellStyled = styled(TableCell)`
  border-bottom: 1px solid lightblue;
`;

const ValidatorTableRow: React.FC<ValidatorTableRowProps> = ({index,data}) => {
    const { formatMessage } = useIntl();
    const mobile = useMobileCheck('724px');
    return (
        <React.Fragment>
            <TableRow>
                <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>{data.description.moniker}</Text>
                </TableCellStyled>
                {!mobile &&(
                <TableCellStyled scope="col" border="bottom">
                    <Text>{shortenAddress(data.evm_address, 6)}</Text>
                </TableCellStyled>
                )}
                {!mobile &&(
                <TableCellStyled scope="col" border="bottom">
                    <Text>{getBalanceNumber(new BigNumber(data.tokens)).toFixed(4)}</Text>
                </TableCellStyled>
                )}
                <TableCellStyled scope="col" border="bottom">
                    <Text>{(Number(data.commission.commission_rates.rate)*100).toFixed(2)}%</Text>
                </TableCellStyled>
                {!mobile &&(
                <TableCellStyled scope="col" border="bottom">
                    <Text>{data.status==3?(<FormattedMessage defaultMessage="Bond" />):(<FormattedMessage defaultMessage="Unbond"/>)}</Text>
                </TableCellStyled>
                 )}
                <TableCellStyled scope="col" border="bottom">
                    <Link to={routesEnum.ValidatorsPage+"/"+data.evm_address}>
                    <Button label={formatMessage({ defaultMessage: 'Delegate' })} />
                    </Link>
                </TableCellStyled>
            </TableRow>
        </React.Fragment>
    )
}

export default ValidatorTableRow