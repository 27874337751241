
import en from './compiled/en.json';
import zh from './compiled/zh.json';

export const messages: { [key: string]: any } = {
 
  en,
  zh,
};

export enum AppLanguage {
  Arabic = 'ar',
  Czech = 'cs',
  German = 'de',
  Greek = 'el',
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Polish = 'pl',
  PortugueseBrazilian = 'pt-br',
  Romanian = 'ro',
  Russian = 'ru',
  Turkish = 'tr',
  ChineseSimplified = 'zh',
}

export const supportedLanguages: String[] = Object.values(AppLanguage);
