import { useCallback } from "react";

import { useWallet } from "use-wallet";
import { Contract } from "web3-eth-contract";
import { useWeb3React } from "@web3-react/core";

import { delegateSnp } from "../snp/utils";

const useSnpDelegate = (lpContract: Contract) => {
  
  const {  account } = useWeb3React();

  const handleDelegateSnp = useCallback(
    async (validator: string,amount: string) => {
      try {
      const txHash = await delegateSnp(
        lpContract,
        validator,
        amount,
        account
      );
      console.log(txHash);
      return txHash
    } catch (e) {
      return false
    }
    },
    [account]
  );

  return { onSnpDelegate: handleDelegateSnp };
};

export default useSnpDelegate;
