import React, { useCallback,useMemo, useState }from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import _shuffle from 'lodash/shuffle';
import BigNumber from 'bignumber.js';
import ReactTooltip from 'react-tooltip';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import { useWeb3React } from '@web3-react/core';
import { provider } from "web3-core";
import cogoToast from 'cogo-toast';


import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FormNext } from 'grommet-icons';
import { Link } from '../../components/Link';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Paper } from '../../components/Paper';
import { TopupInput } from '../../components/TopUpInput';
import { ConnectWalletButton } from '../../components/ConnectWalletButton';
import { Alert } from '../../components/Alert';
import Spacer from "../../components/Spacer";

import useMobileCheck from '../../hooks/useMobileCheck';

import shortenAddress from '../../utils/shortenAddress';

import { networkID,seeleNetworkID, contractAddresses } from "../../constants";
import {getBalanceNumber,getFullDisplayBalance} from "../../utils/formatBalance";
import { getContract,getDelegateContract } from "../../utils/erc20";
import useTokenBalance from "../../hooks/useTokenBalance";
import useAllowanceEx from "../../hooks/useAllowanceEx";
import useApproveEx from "../../hooks/useApproveEx";
import useSnpDelegate from "../../hooks/useSnpDelegate";

import {
    PRICE_PER_VALIDATOR,
    TICKER_NAME,
    CONTRACT_ADDRESS,
    ETHER_TO_GWEI,
  } from '../../utils/envVars';


const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 32px;
`;

const ValidatorDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid lightblue;
  background: #eaf6f9;
  border-radius: 5px;
  @media screen and (max-width: 724px) {
    flex-direction: column;
    .details-item {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const InputContainer = styled.div`
  margin: auto auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const AccountDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
  border: 1px solid lightblue;
  background: #eaf6f9;
  border-radius: 5px;
  padding: 10px 20px;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    justify-content: flex-start;
    align-items: left;
  }
`;

const ValContainer = styled.div`
  margin: auto auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const BoldGreen = styled.span`
  color: ${(p: { theme: any; fontSize: number }) => p.theme.green.dark};
  font-size: ${(p: { theme: any; fontSize: number }) => p.fontSize}px;
  font-weight: bold;
`;

const SubmitButton = styled(Button)`
  height: 50px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin: 10px;
  }
`;


interface RouteParams {
    address: string;
}

const ValidatorDetailPage:React.FC = () => {
  const { formatMessage } = useIntl();
  const params = useParams<RouteParams>();
  const mobile = useMobileCheck('724px');
  const { account,chainId, library  } = useWeb3React()

  const waitTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Waiting For Transaction`,
    });
  }, [formatMessage]);


  const successfulTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Transaction Successful`,
    });
  }, [formatMessage]);

  const failTxText = React.useMemo(() => {
    return formatMessage(
    {
      defaultMessage: `Transaction Failed`,
    });
  }, [formatMessage]);

  const balance = useTokenBalance(contractAddresses.snp[seeleNetworkID]);
  const lpContract = useMemo(() => {
    if (library)
        return getContract(library.provider as provider, contractAddresses.snp[seeleNetworkID]);
    return null
  }, [library]);

  const lpDelegateContract = useMemo(() => {
    if (library)
        return getDelegateContract(library.provider as provider, contractAddresses.snpstake[seeleNetworkID]);
    return null
  }, [library]);

  const allowanceEx = useAllowanceEx(lpContract,contractAddresses.snpstake[seeleNetworkID]);
  const {onApproveEx}  = useApproveEx(lpContract,contractAddresses.snpstake[seeleNetworkID]);

  const [requestedApproval, setRequestedApproval] = useState(false);
  const handleApprove = useCallback(async () => {
    setRequestedApproval(true);
    const { hide } = cogoToast.loading(waitTxText, { hideAfter: 0 });
    try {
      const txHash = await onApproveEx();
      console.log(txHash);
      // user rejected tx or didn't go thru
      if (!txHash) {
        cogoToast.error(failTxText);
        setRequestedApproval(false);
      }else{
        cogoToast.success(successfulTxText);
      }
    } catch (e) {
      console.log(e);
      cogoToast.error(failTxText);
    }
    setRequestedApproval(false);
    hide();
  }, [onApproveEx, setRequestedApproval]);

  const { onSnpDelegate } = useSnpDelegate(lpDelegateContract);
  const [pendingDepositTx, setPendingDepositTx] = useState(false);
  
  const maxTopupValue = useMemo(
    () => getBalanceNumber(balance),
    [balance]
  );


  const [value, setValue] = React.useState("0");

  const submitBtnTooltipText = React.useMemo(() => {
    var valueBig = new BigNumber(value)
    if (valueBig.isLessThanOrEqualTo(0) || valueBig.isGreaterThan(balance.dividedBy(new BigNumber(10).pow(18))))
    {
        return formatMessage({
          defaultMessage: 'Please enter a valid value.',
        });
    }
    return '';
  }, [value, maxTopupValue, formatMessage]);

  return (
    <PageTemplate
      title={formatMessage({ defaultMessage: 'Seele Validator' })}
    >
      <div id="top" />
      <Subtitle>
        <FormattedMessage defaultMessage="Delegate your SNP on seele mainnet" />
      </Subtitle>
      
      <ValidatorDetailsContainer className="my10">
        {!!account&&chainId==seeleNetworkID ? (
            <Paper className="mt30">
                <ValContainer>
                    <Text weight={600} color="blueDark">
                    <FormattedMessage defaultMessage="Validator:" />
                    </Text>
                    <Spacer size='md'/>
                    <BoldGreen className="mr10" fontSize={20}>
                    {shortenAddress(params.address, 6)}
                    </BoldGreen>
                </ValContainer>
                <AccountDetailsContainer className="my30">
                    <div className="details-item">
                        <Text weight={600} color="blueDark">
                        <FormattedMessage defaultMessage="Public key" />
                        </Text>
                        <BoldGreen className="mr10" fontSize={20}>
                        {shortenAddress(account, 6)}
                        </BoldGreen>
                    </div>
                    <div className="details-item">
                        <Text weight={600} color="blueDark">
                        <FormattedMessage defaultMessage="Current balance" />
                        </Text>
                        <BoldGreen className="mr10" fontSize={20}>
                        {`${getBalanceNumber(balance).toFixed(4)} ${TICKER_NAME}`}
                        </BoldGreen>
                    </div>
                </AccountDetailsContainer>

                <Alert>
                  <Text weight={600} color="blueDark">
                    <FormattedMessage defaultMessage="Delegate Snp to mine Seele(lock up for 1 month)" />
                  </Text>
                </Alert>
                <InputContainer className="mt30">
                    <TopupInput
                        value={value}
                        setValue={setValue}
                        maxValue={getFullDisplayBalance(balance)}
                    />
                    <Spacer size='sm'/>
                    <span data-tip={submitBtnTooltipText}>
                    {!allowanceEx.toNumber() ?(
                        <SubmitButton
                        className="ml10"
                        active={!requestedApproval}
                        label={formatMessage({ defaultMessage: 'Wallet Approve' })}
                        rainbow
                        disabled = {requestedApproval}
                        onClick={handleApprove}
                        />
                    ):(
                        <SubmitButton
                        className="ml10"
                        label={formatMessage({ defaultMessage: 'Delegate' })}
                        active={!pendingDepositTx}
                        rainbow
                        disabled={
                            new BigNumber(value).isLessThan(1) ||
                            new BigNumber(value).isGreaterThan(balance.dividedBy(new BigNumber(10).pow(18))) ||
                            pendingDepositTx
                        }
                        onClick={async () => {
                            setPendingDepositTx(true);
                            const { hide } = cogoToast.loading(waitTxText, { hideAfter: 0 });
                            try {

                            const txHash = await onSnpDelegate(params.address,value.toString());
                            console.log(txHash);
                            //cogoToast.success(successfulTxText);
                            // user rejected tx or didn't go thru
                            if (!txHash) {
                                cogoToast.error(failTxText);
                                setPendingDepositTx(false);
                            }else{
                                cogoToast.success(successfulTxText);
                            }
                            } catch (e) {
                            console.log(e);
                            cogoToast.error(failTxText);
                            }
                            setPendingDepositTx(false);
                            hide();
                        }
                        }
                        />
                    )}
                    </span>
                    </InputContainer>
            </Paper>
          ):(
            <Paper className="mt30">
              <ConnectWalletButton chainId={seeleNetworkID.toString()}/>
            </Paper>
        )}  
        
      </ValidatorDetailsContainer>
    </PageTemplate>
  );
};

export default ValidatorDetailPage;
