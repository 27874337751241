import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Box, DropButton } from 'grommet';
import { Menu, Language } from 'grommet-icons';

import SeeleSVG from '../static/seelen.svg';

import { trimString } from '../utils/trimString';
import { Dot } from './Dot';
import { Link } from './Link';
import { Text } from './Text';
//import { routesEnum } from '../Routes';
//import { Heading } from './Heading';
import { Heading as H } from 'grommet';

import useMobileCheck from '../hooks/useMobileCheck';
import { FormattedMessage } from 'react-intl';

const RainbowBackground = styled(Box)`
  background-image: ${p => `linear-gradient(to right, ${p.theme.rainbow})`};
`;

const SeeleLogo = styled.img`
  height: 40px;
  width: 40px;
`;


const NavBarLinks = styled.div`
  display: flex;
  @media only screen and (max-width: 1080px) {
    .secondary-link {
      display: none;
    }
  }
`;

const ValidatorDropdown = styled(DropButton)`
  padding: 12px 8px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border: none;
  :hover {
    border: none;
    box-shadow: none;
  }
`;

const DotDropdown = styled(DropButton)`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  :hover {
    transition: transform 0.2s;
    transform: scale(1.1);
  }
`;

const DropdownLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const Card = styled.div``;

const NetworkInfo = styled.div`
  background: ${p => p.theme.gray.light};
  padding: 32px;
`;

const NavLinksRight = styled.div`
  display: flex;
  align-items: center;
`;

const BarLinkText = styled(H)`
  font-family: 'Maison Neue', system-ui, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Helvetica, Arial, sans-serif;
  color: ${p => (p.color ? p.color : p.theme.blue.dark)};
  :not(.no-padding) {
    padding: 0 12px;
    white-space: nowrap;
  }
  margin: 0;
  max-width: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: ${(p: { active?: boolean }) => (p.active ? 'bold' : 300)};

  
`;

const _AppBar = ({ location }: RouteComponentProps) => {
    /*
  const {
    active: walletConnected,
    account,
    chainId,
  }: web3ReactInterface = useWeb3React<Web3Provider>();
  */
  let network;
  let networkAllowed = false;


  const pathname: string = React.useMemo(() => location.pathname, [
    location.pathname,
  ]);


  const mobile = useMobileCheck('1080px');

  const networkName =  'mainnet';

  return (
    <RainbowBackground
      tag="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ left: 'medium', right: 'small', vertical: 'small' }}
      elevation="medium"
      style={{ zIndex: 1 }}
    >
      <NavBarLinks>
        <Link to='/' className="mr30">
          <SeeleLogo src={SeeleSVG} alt="seele-logo" />
          {!mobile && (
            <div className="flex flex-column center ml5">
              <BarLinkText
                active={pathname === '/'}
                level={4}
                margin="none"
                className="bar-link-text no-padding"
              >
                <Text>
                  <FormattedMessage
                    defaultMessage="Seele Launchpad"
                  />
                </Text>
              </BarLinkText>
            </div>
          )}
        </Link>
        
        <Link to='/checklist' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
            active={pathname ==='/checklist'}
          >
            <FormattedMessage defaultMessage="Checklist" />
          </BarLinkText>
        </Link>
        <Link to='/faq' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
            active={pathname === '/faq'}
          >
            <FormattedMessage defaultMessage="FAQ" />
          </BarLinkText>
        </Link>
        <Link to='/topup' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
            active={pathname === '/topup'}
          >
            <FormattedMessage defaultMessage="Become A Delegator" />
          </BarLinkText>
        </Link>
        <Link to='/validators' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
            active={pathname === '/validators'}
          >
            <FormattedMessage defaultMessage="Validators" />
          </BarLinkText>
        </Link>
        <Link to='/stake' className="mx10 secondary-link">
          <BarLinkText
            level={4}
            margin="none"
            className="bar-link-text"
            active={pathname === '/stake'}
          >
            <FormattedMessage defaultMessage="Stake" />
          </BarLinkText>
        </Link>
      </NavBarLinks>
      <NavLinksRight>
        {!mobile && (
          <Link to='/languages' className="mx10 secondary-link">
            <BarLinkText
              level={4}
              margin="none"
              className="bar-link-text"
              active={pathname === '/languages'}
            >
              <FormattedMessage defaultMessage="Languages" />
            </BarLinkText>
          </Link>
        )}
        {mobile && (
          <Link to='/languages' className="mx10">
            <Language color="black" />
          </Link>
        )}
        {mobile && (
          <ValidatorDropdown
            className="secondary-link"
            label={<Menu color="black" />}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <Card>
                <NetworkInfo>
                
                  <span>
                    <FormattedMessage defaultMessage="Seele Launchpad" />{' '}
                  </span>
                </NetworkInfo>
                <Box pad="large" className="mt0">
                  <DropdownLink to='/checklist'>
                    <FormattedMessage defaultMessage="Checklist" />
                  </DropdownLink>
                  <DropdownLink to='/faq'>
                    <FormattedMessage defaultMessage="FAQ" />
                  </DropdownLink>
                  <DropdownLink to='/topup'>
                    <FormattedMessage defaultMessage="Become A Delegator" />
                  </DropdownLink>
                  <DropdownLink to='/validators'>
                    <FormattedMessage defaultMessage="Validators" />
                  </DropdownLink>
                  <DropdownLink to='/stake'>
                    <FormattedMessage defaultMessage="Stake" />
                  </DropdownLink>
                  <DropdownLink to='/languages'>
                    <FormattedMessage defaultMessage="Languages" />
                  </DropdownLink>
                </Box>
              </Card>
            }
          />
        )}
        
      </NavLinksRight>
    </RainbowBackground>
  );
};

export const AppBar = withRouter(_AppBar);
