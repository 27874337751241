import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';
import EthDiamondPlain from '../../static/seelen.svg';
import EthRound from '../../static/snp.svg';
import { Text } from '../../components/Text';
import { Heading } from '../../components/Heading';
import { Link } from '../../components/Link';
import Spacer from "../../components/Spacer";

const Container = styled.div`
  background: ${p => p.theme.white};
`;
const SubContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.largest}) {
    padding: 0 60px;
  }
`;
const ImgContainer = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    display: none;
  }
`;

// adds an opaque eth logo behind the text on small screen sizes
const ContentContainer = styled.div`
  @media only screen and (max-width: ${p => p.theme.screenSizes.medium}) {
    :before {
      content: ' ';
      display: block;
      position: absolute;
      right: 60px;
      top: 35px;
      width: 250px;
      height: 400px;
      z-index: 1;
      opacity: 0.15;
      background-image: url(${EthDiamondPlain});
      background-repeat: no-repeat;
      background-position: 50% 0;
      -ms-background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
`;
export const Introduction = (): JSX.Element => {
  return (
    <Container>
      <SubContainer className="py60 flex">
        <ContentContainer>
          <Spacer/>
          <ScrollAnimation animateIn="fadeIn" animateOnce>
            <Heading level={2} size="medium" color="blueDark" margin="none">
              <FormattedMessage defaultMessage="Delegators and Seele network" />
            </Heading>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <Text className="mt20">
              <FormattedMessage
                defaultMessage="This launchpad will help you become a delegator, so you can play
                  an active part in Seele's future. Delegator are key to the
                  more secure, scalable, and sustainable Seele we're building
                  together."
              />
            </Text>
            <Text className="mt20">
              <FormattedMessage
                defaultMessage="As a delegator, you'll be responsible for securing the network and
                  receive continuous payouts for actions that help the network reach
                  consensus."
              />
            </Text>
            <Text className="mt20">
              <FormattedMessage
                defaultMessage="Today, you'll secure the Seele Network, the Seele launch up.
                  It's a chain that uses a proof-of-stake consensus
                  mechanism. Eventually you'll help secure all of Seele."
              />
            </Text>
            {/* NOTE: this section felt like there was a lot of repetition with the section below so have reframed to talk about validators in the context of the upgrades */}
          </ScrollAnimation>
          <Spacer/>
        </ContentContainer>
      </SubContainer>
    </Container>
  );
};
