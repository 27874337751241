import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _shuffle from 'lodash/shuffle';
import ReactTooltip from 'react-tooltip';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import { useWeb3React } from '@web3-react/core';

import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FormNext } from 'grommet-icons';
import { Link } from '../../components/Link';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Paper } from '../../components/Paper';
import { ConnectWalletButton } from '../../components/ConnectWalletButton';
import { Alert } from '../../components/Alert';
import { routesEnum } from '../Routes';
import useMobileCheck from '../../hooks/useMobileCheck';
import { seeleNetworkID } from "../../constants";
import { querySeelechainValidators } from '../../utils/queryBeaconchain';

import ValidatorTableRow  from '../../components/ValidatorTableRow';




const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 32px;
`;

const ValidatorsDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid lightblue;
  background: #eaf6f9;
  border-radius: 5px;
  @media screen and (max-width: 724px) {
    flex-direction: column;
    .details-item {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const TableCellStyled = styled(TableCell)`
  border-bottom: 1px solid lightblue;
`;

const ValidatorsPage:React.FC = () => {
  const { formatMessage } = useIntl();
  const mobile = useMobileCheck('724px');
  const { account,chainId } = useWeb3React()

  const [state, setState] = useState({
    totalValidators: [],
    status: 0,
  });

  useEffect(() => {
    (async () => {
      const response = await querySeelechainValidators();
      setState({
        totalValidators: response.body.totalValidators,
        status: response.statusCode,
      });
    })();
  }, []);


  const tableBodyRender = React.useMemo(
    () => {
      return state.totalValidators.map(validator => {
        return (
          <ValidatorTableRow  data={validator}/>
        );
      });
    },
		
		[state]
	);

  return (
    <PageTemplate
      title={formatMessage({ defaultMessage: 'Seele Validators' })}
    >
      <div id="top" />
      <Subtitle>
        <FormattedMessage defaultMessage="Choice validator to stake your SNP on seele mainnet" />
      </Subtitle>
      
      <ValidatorsDetailsContainer className="my10">
        {!!account&&chainId==seeleNetworkID ? (
            <Paper style={{ marginTop: '1rem' }}>
              <ReactTooltip />
              <Table>
              <TableHeader>
                <TableRow>
                  <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>
                      <FormattedMessage defaultMessage="Validator" />
                    </Text>
                  </TableCellStyled>
                  {!mobile &&(
                    <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>
                      <FormattedMessage defaultMessage="Address" />
                    </Text>
                    </TableCellStyled>
                  )}

                  {!mobile &&(
                    <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>
                      <FormattedMessage defaultMessage="Delegated(SNP)" />
                    </Text>
                    </TableCellStyled>
                  )}
                  
                  <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>
                      <FormattedMessage defaultMessage="Fee" />
                    </Text>
                  </TableCellStyled>
                  {!mobile &&(
                  <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>
                      <FormattedMessage defaultMessage="Status" />
                    </Text>
                  </TableCellStyled>
                  )}
                  <TableCellStyled scope="col" border="bottom">
                    <Text weight={600}>
                      <FormattedMessage defaultMessage="Action" />
                    </Text>
                  </TableCellStyled>
                </TableRow>
              </TableHeader>
              <TableBody>
                {tableBodyRender}
              </TableBody>
              </Table>
            </Paper>
          ):(
            <Paper className="mt30">
              <ConnectWalletButton chainId={seeleNetworkID.toString()}/>
            </Paper>
        )}  
        
      </ValidatorsDetailsContainer>
    </PageTemplate>
  );
};

export default ValidatorsPage;
