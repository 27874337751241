import { useEffect, useState } from "react";
import Web3 from "web3";
import { provider } from "web3-core";
import { useWallet } from "use-wallet";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';

const useBlock = () => {
  const [block, setBlock] = useState(0);
  //const { ethereum }: { ethereum: provider } = useWallet();
  const {   library } = useWeb3React();
  useEffect(() => {
    if (!library) return;
    const web3 = new Web3(library.provider);

    const interval = setInterval(async () => {
      const latestBlockNumber = await web3.eth.getBlockNumber();
      if (block !== latestBlockNumber) {
        setBlock(latestBlockNumber);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [library,block]);

  return block;
};

export default useBlock;
