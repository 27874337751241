import React from "react";
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from '../utils/web3React'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ModalsProvider from "../contexts/Modals";
import TransactionProvider from '../contexts/Transactions'
import Header from "../components/Header";
//import Home from "./Home";

import FAQ from "./FAQ";
import Languages from "./Languages";

import LandingPage from "./Landing";

import { createBrowserHistory } from 'history';
import { Grommet } from 'grommet'
import { grommetTheme } from '../styles/grommetTheme';
import { styledComponentsTheme } from '../styles/styledComponentsTheme';
import { GlobalStyles } from '../styles/GlobalStyles';
import { Footer } from '../components/Footer';
import { LocalizedRouter } from '../components/LocalizedRouter';
import { Routes as RoutedContent } from './Routes';
import { networkID, chainConfig } from "../constants";

export const history = createBrowserHistory();


const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
    <LocalizedRouter history={history}>
    <Grommet theme={grommetTheme}>
      <ThemeProvider theme={styledComponentsTheme}>
          <TransactionProvider>
            <ModalsProvider>{children}</ModalsProvider>
          </TransactionProvider>
    </ThemeProvider>
    </Grommet>
    </LocalizedRouter>
    </Web3ReactProvider>
  );
};

const App: React.FC = () => {
  return (
    <Providers>
      <GlobalStyles />
      <RoutedContent />
      <Footer />
    </Providers>
  );
};

export default App;
