import axios from 'axios';
import { BEACONCHAIN_URL, ETH_DEPOSIT_OFFSET } from './envVars';

type BeaconchainResponse = {
  data: {
    status: string;
    data: {
      validatorscount: number;
      totalvalidatorbalance: number; // gwei
    };
  };
};

export const queryBeaconchain = async () => {
  try {
    const response: BeaconchainResponse = await axios.get(
      `${BEACONCHAIN_URL}/api/v1/epoch/latest`
    );
    let ethBalance = response.data.data.totalvalidatorbalance * 1e-9;
    ethBalance = +ethBalance.toFixed(0);
    return {
      statusCode: 200,
      body: {
        totalValidators: response.data.data.validatorscount,
        amountEth: ethBalance + ETH_DEPOSIT_OFFSET,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: {
        totalValidators: 0,
        amountEth: 0,
      },
    };
  }
};


type seelechainResponse = {
  data: {
    Error:{
      Code: number;
      Message: string; 
      UserMessage: string; 
    };
    Data: {
      amount: number;
      delegators: number; 
    };
  };
};

export const querySeelechain = async () => {
  try {
    const response: seelechainResponse = await axios.get(
      `https://launchpad.seelen.pro/api/v1/status`
    );
    let ethBalance = response.data.Data.amount ;
    ethBalance = +ethBalance.toFixed(0);
    return {
      statusCode: 200,
      body: {
        totalValidators: response.data.Data.delegators,
        amountEth: ethBalance,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: {
        totalValidators: 0,
        amountEth: 0,
      },
    };
  }
};

type seelechainValidatorsResponse = {
  data: {
    Error:{
      Code: number;
      Message: string; 
      UserMessage: string; 
    };
    Data: [];
  };
};

export const querySeelechainValidators = async () => {
  try {
    const response: seelechainValidatorsResponse = await axios.get(
      `https://launchpad.seelen.pro/api/v1/validators`
    );
    return {
      statusCode: 200,
      body: {
        totalValidators: response.data.Data?response.data.Data:[],
      },
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: {
        totalValidators: [],
      },
    };
  }
};

type seelechainDelegatesResponse = {
  data: {
    Error:{
      Code: number;
      Message: string; 
      UserMessage: string; 
    };
    Data: [];
  };
};

export const querySeelechainDelegates = async (address:string) => {
  try {
    const response: seelechainDelegatesResponse = await axios.get(
      `https://launchpad.seelen.pro/api/v1/delegator/` + address
    );
    return {
      statusCode: 200,
      body: {
        delegates: response.data.Data?response.data.Data:[],
      },
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: {
        delegates: [],
      },
    };
  }
};

type seelechainRewardsResponse = {
  data: {
    Error:{
      Code: number;
      Message: string; 
      UserMessage: string; 
    };
    Data: {
      rewards: [];
      total: [];
    };
  };
};

export const querySeelechainRewards = async (address:string) => {
  try {
    console.log(`https://launchpad.seelen.pro/api/v1/delegatorrewards/` + address);
    const response: seelechainRewardsResponse = await axios.get(
      `https://launchpad.seelen.pro/api/v1/delegatorrewards/` + address
    );
    //console.log(response.data);
    return {
      statusCode: 200,
      body: {
        rewards: response.data.Data?response.data.Data.rewards:[],
      },
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: {
        rewards: [],
      },
    };
  }
};