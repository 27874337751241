import { useCallback } from "react";

import useSnp from "./useSnp";
import { useWallet } from "use-wallet";
import { Contract } from "web3-eth-contract";
import { useWeb3React } from "@web3-react/core";

import { sendToSeelechain, getSeeleBridgeContract,getSnpMasterContract } from "../snp/utils";

const useStakeSnp = (lpContract: Contract) => {
  //const { account } = useWallet();
  const {  account } = useWeb3React();

  const snp = useSnp();

  const handleStakeSnp = useCallback(
    async (tokenContract: string,destination: string,amount: string) => {
      try {
      const txHash = await sendToSeelechain(
        lpContract,
        tokenContract,
        destination,
        amount,
        account
      );
      console.log(txHash);
      return txHash
    } catch (e) {
      return false
    }
    },
    [account,  snp]
  );

  return { onStakeSnp: handleStakeSnp };
};

export default useStakeSnp;
