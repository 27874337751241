import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from '@ethersproject/providers';
import { getBalance } from "../utils/erc20";
import useBlock from "./useBlock";

const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0));
  /*
  const {
    account,
    ethereum,
  }: { account: string; ethereum: provider } = useWallet();
  */
  const {  account, library } = useWeb3React();
  const block = useBlock();

  const fetchBalance = useCallback(async () => {
    const balance = await getBalance(library.provider, tokenAddress, account);
    setBalance(new BigNumber(balance));
  }, [account, library, tokenAddress]);

  useEffect(() => {
    if (account && library) {
      fetchBalance();
    }
  }, [account, library, setBalance, block, tokenAddress]);

  return balance;
};

export default useTokenBalance;
