import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { Link } from '../../../components/Link';
import { Step } from './Step';
import { routesEnum } from '../../Routes';

const Container = styled.div`
  background-color: ${p => p.theme.green.light};
  box-sizing: border-box;
  width: 100%;
  padding: ${(p: { isMobile: boolean }) => (p.isMobile ? '64px 0' : '124px 0')};
  
`;

const Content = styled.div`
  max-width: ${p => p.theme.screenSizes.largest};
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;
  @media only screen and (max-width: ${p => p.theme.screenSizes.largest}) {
    padding: ${(p: { isMobile: boolean }) =>
      p.isMobile ? '0 20px' : '0 60px'};
  }
`;

const StepsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  @media only screen and (max-width: 1380px) {
    justify-content: center;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SignupSteps = (): JSX.Element => {
  const m: boolean = (window as any).mobileCheck();
  const intl = useIntl();
  return (
    <Container className="py100" isMobile={m}>
      <Content isMobile={m}>
      <ScrollAnimation animateIn="fadeIn" animateOnce>
        <Heading level={2} size="medium" color="blueDark" margin="none">
          <FormattedMessage defaultMessage="Become a delegator" />
        </Heading>
        <Subtitle>
          <FormattedMessage
            defaultMessage="Becoming a delegator is a big responsibility with important
          preparation steps. Only start the deposit process when you're ready."
          />
        </Subtitle>
      </ScrollAnimation>
      <StepsContainer>
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <Step
            emoji="📚"
            emojiAlt={intl.formatMessage({ defaultMessage: 'books' })}
            title={intl.formatMessage({
              defaultMessage: '1. Learn about your responsibilities',
            })}
            content={intl.formatMessage({
              defaultMessage:
                'The Seele launchs will only be successful if delegators understand the risks and responsibilities.',
            })}
          >
            <Link to={routesEnum.FaqPage} primary>
              <FormattedMessage defaultMessage="Launchpad FAQ" />
            </Link>
          </Step>
        </ScrollAnimation>
        
        <ScrollAnimation animateIn="fadeInUp" animateOnce delay={150}>
          <Step
            emoji="💰"
            emojiAlt={intl.formatMessage({ defaultMessage: 'money bag' })}
            title={intl.formatMessage({ defaultMessage: '2. Time to deposit' })}
            content={intl.formatMessage({
              defaultMessage:
                "Once you're comfortable, you'll go through depositing your SNP by seele bridge.",
            })}
          >
            <Link to={routesEnum.topUpPage} primary>
              <FormattedMessage defaultMessage="Start deposit process" />
            </Link>
          </Step>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
          <Step
            emoji="🕰"
            emojiAlt={intl.formatMessage({ defaultMessage: 'clock' })}
            title={intl.formatMessage({
              defaultMessage: '3. Wait to become active',
            })}
            content={intl.formatMessage({
              defaultMessage:
                "Once set up, your delegtator won't become active straight away. We will check to ensure that there are enough assets to activate the mainnet.",
            })}
          >
            <Link to={routesEnum.checklistPage} primary>
              <FormattedMessage defaultMessage="Complete checklist" />
            </Link>
          </Step>
        </ScrollAnimation>
      </StepsContainer>
      </Content>
    </Container>
  );
};
